import React, { useState } from "react";
import { Helmet } from "react-helmet";

import WeeklyList from "./view/WeeklyList";
import Banner from "./view/Banner";
import WeeklyMenu from "./view/WeeklyMenu";

const Adult = () => {
    const [selectedMenu, setSelectedMenu] = useState({
        type: "day",
        value: null,
    });

    const handleMenuClick = (selectedMenu) => {
        setSelectedMenu(selectedMenu);
    };

    return (
        <div className="">
            <Helmet>
                <title>메가툰 - 성인 웹툰</title>
                <meta
                    name="description"
                    content="추천 성인 웹툰을 다른 사람 눈치보며 몰래 보기"
                />
            </Helmet>
            <WeeklyMenu onMenuClick={handleMenuClick} />

            <WeeklyList
                category="adult"
                title="성인 웹툰"
                menu={selectedMenu}
            />

            <Banner />
        </div>
    );
};

export default Adult;
