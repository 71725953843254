const Banner = () => {
    return (
        <div className="flex justify-center items-center max-w-screen-xl md:px-20 md:mt-10 mb-4 md:mb-10 mx-auto overflow-hidden">
            <img
                src="/banner/head_ad.png"
                className="w-full object-cover"
                alt="logo"
            ></img>
        </div>
    );
};

export default Banner;
