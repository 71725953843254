import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";

import Header from "./view/Header";
import Home from "./Home";
import Footer from "./view/Footer";
import General from "./General";
import BLGL from "./BLGL";
import Adult from "./Adult";
import Search from "./Search";
import Detail from "./Detail";
import View from "./View";

import "./App.css";

const App = () => {
    return (
        <Router>
            <Helmet>
                <title>메가툰 - 대한민국 No.1 무료 웹툰 사이트</title>
                <meta
                    name="description"
                    content="네이버, 카카오, 레진코믹스 등 대한민국 모든 웹툰을 무제한으로 즐겨보세요."
                />
            </Helmet>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/general" element={<General />} />
                <Route path="/adult" element={<Adult />} />
                <Route path="/blgl" element={<BLGL />} />
                <Route path="/search/:keyword" element={<Search />} />
                <Route path="/detail/:id" element={<Detail />} />
                <Route path="/view/:id/:episode" element={<View />} />
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;
