import React from "react";
import { Helmet } from "react-helmet";

import SearchList from "./view/SearchList";
import Banner from "./view/Banner";
import { useParams } from "react-router-dom";


const Search = () => {
    const { keyword } = useParams();
    return (
        <div className="">
            <Helmet>
                <title>메가툰 - '{keyword}' 검색결과</title>
                <meta
                    name="description"
                    content="네이버, 카카오, 레진코믹스 등 대한민국 모든 웹툰을 무제한으로 즐겨보세요."
                />
            </Helmet>

            <SearchList keyword={keyword} />

            <Banner />
        </div>
    );
};

export default Search;
