import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { API_SERVER, IMG_SERVER } from "../config";

const SearchList = ({ keyword }) => {
    const [data, setData] = useState(null);
    const [cursor, setCursor] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [completed, setCompleted] = useState(false);

    const fetchData = useCallback(async (cursorParam = null) => {
        try {
            cursorParam === null && setData(null);

            setIsLoading(true);
            const cursorToSend = cursorParam === null ? "null" : cursorParam;
            const completedParam = completed ? "true" : "false";

            const { data: newData } = await axios.get(
                `${API_SERVER}/search/${keyword}/${completedParam}/${cursorToSend}`
            );
            if (cursorParam === null) {
                setData(newData);
            } else {
                setData((prevData) => ({
                    ...prevData,
                    toons: [...prevData.toons, ...newData.toons],
                }));
            }
            setCursor(
                newData.toons.length
                    ? newData.toons[newData.toons.length - 1].id
                    : null
            );
            setHasMore(newData.toons.length === 30);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    }, [keyword, completed]);

    useEffect(() => {
        fetchData();
    }, [keyword, completed, fetchData]);


    const fetchMoreData = () => {
        if (hasMore && !isLoading) {
            fetchData(cursor);
        }
    };

    if (!data) {
        return (
            <div className="max-w-screen-xl mt-3 md:mt-7 px-3 md:px-20 mx-auto">
                <div className="flex font-bold space-x-5 py-1 -mr-1 mb-3">
                    <span className="text-xl w-1/2 text-gray-500 space-x-2">
                        <span className="text-black text-2xl font-bold">
                            '{keyword}'
                        </span>
                        <span>검색결과</span>
                    </span>
                </div>
                <div className="flex max-w-screen-xl -mt-32 h-screen mx-auto justify-center items-center">
                    <img src="/loading.gif" width="96px" alt="loading..." />
                </div>
            </div>
        );
    }

    return (
        <div className="max-w-screen-xl mt-3 md:mt-7 px-3 md:px-20 mx-auto">
            <div className="flex font-bold space-x-5 py-1 -mr-1 mb-3">
                <span className="text-xl w-5/6 text-gray-500 space-x-2">
                    <span className="text-black text-xl md:text-2xl font-bold">
                        '{keyword}'
                    </span>
                    <span className="text-base md:text-normal">검색결과</span>
                </span>
                {data && (
                    <span className="flex w-full text-sm md:text-base justify-end text-gray-500">
                        <button
                            className={`px-1 ${
                                !completed ? "text-theme_rose" : ""
                            }`}
                            onClick={() => setCompleted(false)}
                        >
                            연재({data.total[0]})
                        </button>
                        ·
                        <button
                            className={`px-1 ${
                                completed ? "text-theme_rose" : ""
                            }`}
                            onClick={() => setCompleted(true)}
                        >
                            완결({data.total[1]})
                        </button>
                    </span>
                )}
            </div>

            <div className="grid grid-cols-3 md:grid-cols-6 items-center gap-2 md:gap-4 py-2">
                {data.toons.map((toon, index) => (
                    <Link
                        to={`/detail/${toon.id}`}
                        key={index}
                        className="relative"
                    >
                        <img
                            src={`${IMG_SERVER}/${toon.image}`}
                            className="w-full h-[160px] md:h-[225px] rounded-md object-cover"
                            alt="thumbnail"
                        />
                        {toon.isAdult === 1 && (
                            <p className="absolute top-1 md:top-1.5 right-1 md:right-1.5 px-0.5 md:px-1 rounded-sm text-white font-bold bg-red-500 text-xs md:text-sm">
                                19
                            </p>
                        )}

                        <div className="py-2 pb-4 text-sm md:text-base">
                            <p className="font-bold truncate">{toon.title}</p>
                            <p className="flex text-xs text-gray-500">
                                <span className="truncate">
                                    {toon.authors.map((author, index) => (
                                        <React.Fragment key={index}>
                                            {author.name}
                                            {index !==
                                                toon.authors.length - 1 && ", "}
                                        </React.Fragment>
                                    ))}
                                </span>
                            </p>
                            <p className="inline-flex items-center text-gray-500 text-xs mt-1 pl-1 pr-1.5 space-x-1 border">
                                <img
                                    className="w-3 md:w-3.5 h-3 md:h-3.5"
                                    src={`${toon.platforms[0].icon}`}
                                    alt="company"
                                />
                                <span className="flex items-center">
                                    {toon.platforms[0].displayName}
                                </span>
                            </p>
                        </div>
                    </Link>
                ))}
            </div>

            {hasMore && (
                <div className="flex justify-center my-4">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded"
                        onClick={fetchMoreData}
                        disabled={isLoading}
                    >
                        {isLoading ? "Loading more..." : "More"}
                    </button>
                </div>
            )}
        </div>
    );
};

export default SearchList;
