import React, { useRef, useState, useEffect, memo } from "react";
import { Link } from "react-router-dom";
import { IMG_SERVER } from "../config";

const ScrollButton = memo(({ direction, onClick, hidden }) => (
    <button
        onClick={onClick}
        className={`hidden md:flex absolute z-20 top-1/3 -translate-y-1/2 p-2 rounded-full shadow items-center justify-center bg-gray-100 ${
            direction === "left" ? "-left-6" : "-right-6"
        } ${hidden ? "hidden" : "visible"}`}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.8}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d={
                    direction === "left"
                        ? "M15.75 19.5 8.25 12l7.5-7.5"
                        : "m8.25 4.5 7.5 7.5-7.5 7.5"
                }
            />
        </svg>
    </button>
));

const MonthlyRank = ({ list }) => {
    const scrollContainerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState({
        left: true,
        right: false,
    });

    useEffect(() => {
        const scrollContainerCurrent = scrollContainerRef.current;

        const checkScrollPosition = () => {
            if (!scrollContainerCurrent) return;
            const { scrollLeft, offsetWidth, scrollWidth } =
                scrollContainerCurrent;
            const isAtLeft = scrollLeft === 0;
            const isAtRight =
                Math.ceil(scrollLeft) + offsetWidth >= scrollWidth - 1;
            setScrollPosition({ left: isAtLeft, right: isAtRight });
        };

        checkScrollPosition();
        scrollContainerCurrent.addEventListener("scroll", checkScrollPosition);

        return () =>
            scrollContainerCurrent.removeEventListener(
                "scroll",
                checkScrollPosition
            );
    }, []);

    const scroll = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = scrollContainerRef.current.offsetWidth;
            scrollContainerRef.current.scrollBy({
                left: direction === "left" ? -scrollAmount : scrollAmount,
                behavior: "smooth",
            });
        }
    };

    if (!list || !list.toons || list.toons.length === 0) {
        return <div>데이터가 없습니다.</div>;
    }

    return (
        <div className="max-w-screen-xl mt-3 md:mt-7 px-3 md:px-20 mx-auto">
            <div className="flex justify-between font-bold space-x-5 py-1 -mr-1">
                <span className="text-lg md:text-xl w-3/4">{list.title}</span>
                {/* <button className="flex w-auto justify-end pr-0 text-sm md:text-base text-gray-500">
                    더보기
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4 md:w-5 md:h-5 mt-0.5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5l7 7-7 7"
                        />
                    </svg>
                </button> */}
            </div>

            <div className="relative">
                <ScrollButton
                    direction="left"
                    onClick={() => scroll("left")}
                    hidden={scrollPosition.left}
                />
                <div
                    ref={scrollContainerRef}
                    className="snap-x flex overflow-x-auto scroll-smooth scrollbar-hide"
                >
                    {list.toons.map((item, index) => (
                        <Link
                            to={`/detail/${item.id}`}
                            className="snap-start inline-block min-w-[110px] mr-2 last:mr-0 flex-none relative"
                            key={index}
                        >
                            <img
                                src={`${IMG_SERVER}/${item.image}`}
                                className="w-[110px] md:w-[217.8px] h-[150px] md:h-[290px] rounded-md object-cover"
                                alt="thumbnail"
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="-mt-6 w-[110px] md:w-[170px] h-[40px]"
                            >
                                <text
                                    fill="transparent"
                                    stroke="white"
                                    strokeWidth="5"
                                    x="5"
                                    y="40"
                                    fontWeight="bold"
                                    className="text-[50px]"
                                >
                                    {index + 1}
                                </text>
                                <text
                                    fill="black"
                                    x="5"
                                    y="40"
                                    fontWeight="bold"
                                    className="text-[50px]"
                                >
                                    {index + 1}
                                </text>
                            </svg>

                            {item.isAdult === 1 && (
                                <div className="absolute top-1 md:top-1.5 right-1 md:right-1.5 px-0.5 md:px-1 rounded-sm text-white font-bold bg-red-500 text-xs md:text-sm">
                                    19
                                </div>
                            )}

                            <div className="py-2 pb-4 w-[110px] md:w-[215px]">
                                <p className="font-bold text-sm md:text-base truncate">
                                    {item.title}
                                </p>
                                <p className="text-xs text-gray-500 truncate overflow-hidden whitespace-nowrap text-overflow-ellipsis">
                                    {item.authors.map((author, index) => (
                                        <React.Fragment key={index}>
                                            {author.name}
                                            {index !==
                                                item.authors.length - 1 && ", "}
                                        </React.Fragment>
                                    ))}
                                </p>
                                <p className="inline-flex items-center text-gray-500 text-xs mt-1 pl-1 pr-1.5 space-x-1 border">
                                    <img
                                        className="w-3 md:w-3.5 h-3 md:h-3.5"
                                        src={`${item.platforms[0].icon}`}
                                        alt="company"
                                    />
                                    <span className="flex items-center">
                                        {item.platforms[0].displayName}
                                    </span>
                                </p>
                            </div>
                        </Link>
                    ))}
                </div>
                <ScrollButton
                    direction="right"
                    onClick={() => scroll("right")}
                    hidden={scrollPosition.right}
                />
            </div>
        </div>
    );
};

export default MonthlyRank;
