import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaTelegramPlane } from "react-icons/fa";
import { IoMdArrowBack } from "react-icons/io";


const banners = [
    {
        pc: "/banner/bettoday_lg.png",
        mobile: "/banner/bettoday.png",
        bg: "#80DAF4",
        href: "",
    },
    {
        pc: "/banner/dafabet_lg.png",
        mobile: "/banner/dafabet.png",
        bg: "#0E1A40",
        href: "https://dafabetofficial.com/kr/",
    },
];

const randomBanner = () => {
    const idx = Math.floor(Math.random() * banners.length);
    return banners[idx];
};

const Header = () => {
    const selectedBanner = randomBanner();
    const [searchQuery, setSearchQuery] = useState("");
    const [isSearchActive, setIsSearchActive] = useState(false);
const inputRef = useRef(null);

    const location = useLocation();
    const navigate = useNavigate();

    const isActive = (path) => {
        return location.pathname === path;
    };

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchQuery.trim()) {
            navigate(`/search/${searchQuery}`);
        }
        setSearchQuery("");
    };

      const handleMobileSearch = (e) => {
          e.preventDefault();
          setIsSearchActive(false);
          if (searchQuery.trim()) {
              navigate(`/search/${searchQuery}`);
          }
          setSearchQuery("");
      };

      useEffect(() => {
          if (isSearchActive && inputRef.current) {
              inputRef.current.focus();
          }
      }, [isSearchActive]);

    const handleSearchActivation = (value = true) => {
        setIsSearchActive(value);
    };

    return (
        <header className="items-center max-w-screen bg-darkgray">
            <Link to={selectedBanner.href}>
                <div
                    className={`flex md:hidden w-full h-16 justify-center`}
                    style={{ backgroundColor: selectedBanner.bg }}
                >
                    <img
                        src={selectedBanner.mobile}
                        className="h-full"
                        alt="top Ad"
                    />
                </div>
                <div
                    className={`hidden md:flex w-full justify-center`}
                    style={{ backgroundColor: selectedBanner.bg }}
                >
                    <img
                        src={selectedBanner.pc}
                        className="h-full"
                        alt="top Ad"
                    />
                </div>
            </Link>

            <div className="hidden md:flex items-center max-w-screen-xl px-20 h-20 mx-auto relative bg-darkgray text-white">
                <div className="basis-2/3 flex relative space-x-8">
                    <Link to="/">
                        <img
                            src="/megatoon.png"
                            className="w-[170px]"
                            alt="logo"
                        ></img>
                    </Link>

                    <div className="flex relative space-x-4 text-gray-400 text-lg -mt-0.5">
                        <Link to="/" className="text-white text-xl">
                            웹툰
                        </Link>
                        <p>|</p>
                        <Link to="https://go.megaporn.xyz">야동</Link>
                        <p>|</p>
                        <a
                            href="https://t.me/megatoon9"
                            target="_blank"
                            rel="noopener noreferrer"
                            className=""
                        >
                            <div className="flex flex-row items-center gap-0.5">
                                <FaTelegramPlane />
                                최신주소
                            </div>
                        </a>
                        {/* <Link onClick={() => alert("서비스 준비중입니다.")}>
                            TV/영화
                        </Link> */}
                    </div>
                </div>

                <div className="flex-grow flex items-center justify-end basis-1/3">
                    <div className="mt-2 flex rounded-md shadow-sm w-2/3">
                        <form
                            className="relative flex flex-grow items-stretch focus-within:z-10 border-gray-500 focus:border-theme_rose hover:border-theme_rose border rounded-md"
                            onSubmit={handleSearch}
                        >
                            <input
                                className="block w-full rounded-none rounded-l-md border-none py-1.5 pl-3 text-white text-sm bg-darkgray placeholder:text-gray-400 leading-6 focus:outline-none focus:ring-[#ea3776] hover:ring-[#ea3776]"
                                placeholder="제목/작가 검색"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <button
                                type="submit"
                                className="relative inline-flex items-center rounded-r px-2.5 py-1 bg-darkgray hover:bg-[#ea3776] focus:bg-[#ea3776] hover:ring-[#ea3776] focus:ring-[#ea3776]"
                            >
                                <svg
                                    width="21"
                                    height="21"
                                    viewBox="0 0 21 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617Z"
                                        fill="#FFF21F"
                                    />
                                </svg>
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            <div className="flex md:hidden items-center px-3 w-full h-14 mx-auto relative bg-darkgray text-white">
                <div className="flex relative h-6">
                    <Link to="/" className="mt-1 mr-4">
                        <img
                            src="/megatoon.png"
                            className="w-[120px]"
                            alt="logo"
                        ></img>
                    </Link>

                    <div className="flex relative space-x-3 text-gray-400 text-sm ">
                        <Link to="/" className="text-white text-base">
                            웹툰
                        </Link>
                        <p>|</p>
                        <Link to="https://go.megaporn.xyz">야동</Link>
                        <p>|</p>
                        <a
                            href="https://t.me/megatoon9"
                            target="_blank"
                            rel="noopener noreferrer"
                            className=""
                        >
                            <div className="flex flex-row items-center gap-0.5">
                                <FaTelegramPlane />
                                최신주소
                            </div>
                        </a>
                        {/* <Link onClick={() => alert("서비스 준비중입니다.")}>
                            TV/영화
                        </Link> */}
                    </div>
                </div>
                <div className="flex-grow flex items-center justify-end">
                    {!isSearchActive ? (
                        <div className="relative flex flex-grow items-stretch justify-end focus-within:z-10 border-gray-500 focus:border-theme_rose hover:border-theme_rose">
                            <button
                                onClick={handleSearchActivation}
                                className="relative inline-flex items-center rounded-r py-1 bg-darkgray"
                            >
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 21 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617Z"
                                        fill="#FFF21F"
                                    />
                                </svg>
                            </button>
                        </div>
                    ) : (
                        <div className="absolute inset-0 z-20 bg-darkgray w-full h-14 flex items-center justify-center">
                            <form
                                className="relative flex flex-grow items-center focus-within:z-10 border-gray-500 focus:border-theme_rose hover:border-theme_rose"
                                onSubmit={handleMobileSearch}
                            >
                                <IoMdArrowBack
                                    className="text-4xl ml-2"
                                    onClick={() =>
                                        handleSearchActivation(false)
                                    }
                                />

                                <input
                                    name="searchInput"
                                    className="w-full p-2 border-none bg-darkgray rounded-l focus:outline-none focus:ring-0"
                                    ref={inputRef}
                                    value={searchQuery}
                                    onChange={(e) =>
                                        setSearchQuery(e.target.value)
                                    }
                                />
                                <button
                                    type="submit"
                                    className="relative inline-flex items-center rounded-r py-1 bg-darkgray px-3"
                                >
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 21 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617Z"
                                            fill="#FFF21F"
                                        />
                                    </svg>
                                </button>
                            </form>
                        </div>
                    )}
                </div>
            </div>

            <div className="flex relative space-x-9 text-gray-400 text-lg bg-white border-b-1 border">
                <div className="flex justify-center md:justify-start w-full mx-auto text-sm md:text-base font-semibold md:font-bold space-x-3 md:space-x-6 max-w-screen-xl mt-1 md:mt-3 px-3 md:px-20 gap-4">
                    <Link
                        to="/"
                        className={
                            isActive("/")
                                ? "text-theme_rose border-theme_rose pb-2 border-b-2"
                                : "text-black"
                        }
                    >
                        추천
                    </Link>
                    <Link
                        to="/general"
                        className={
                            isActive("/general")
                                ? "text-theme_rose border-theme_rose pb-2 border-b-2"
                                : "text-black"
                        }
                    >
                        일반웹툰
                    </Link>
                    <Link
                        to="/adult"
                        className={
                            isActive("/adult")
                                ? "text-theme_rose border-theme_rose pb-2 border-b-2"
                                : "text-black"
                        }
                    >
                        성인웹툰
                    </Link>
                    <Link
                        to="/blgl"
                        className={
                            isActive("/blgl")
                                ? "text-theme_rose border-theme_rose pb-2 border-b-2"
                                : "text-black"
                        }
                    >
                        BL/GL
                    </Link>
                </div>
            </div>
        </header>
    );
};

export default Header;
