import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import MonthlyRank from "./view/MonthlyRank";
// import Banner from "./view/Banner";
import RealTimeBest from "./view/RealTimeBest";
import SlideList from "./view/SlideList";
import { API_SERVER } from "./config";
import TopBanner from "./view/TopBanner";

const Home = () => {
    const [list, setList] = useState([]);
    const targetUrl = `${API_SERVER}/home`;

    useEffect(() => {
        console.log(targetUrl);
        fetch(targetUrl)
            .then((response) => response.json())
            .then((data) => {
                setList(data);
            })
            .catch((error) => console.error("Error fetching data:", error));
    }, [targetUrl]);

    if (list.length === 0) {
        return (
            <div className="flex max-w-screen-xl -mt-32 h-screen mx-auto justify-center items-center">
                <img src="/loading.gif" width="96px" alt="loading..." />
            </div>
        );
    }

    return (
        <div>
            <Helmet>
                <title>메가툰 - 대한민국 No.1 무료 웹툰 사이트</title>
                <meta
                    name="description"
                    content="네이버, 카카오, 레진코믹스 등 대한민국 모든 웹툰을 무제한으로 즐겨보세요."
                />
            </Helmet>
            <MonthlyRank list={list[0]} />

            <RealTimeBest list={list[1]} />

            <TopBanner />

            {list.slice(2).map((toons, index) => (
                <SlideList key={index} list={toons} />
            ))}

            <TopBanner />
            {/* <Banner /> */}
        </div>
    );
};

export default Home;
