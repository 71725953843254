import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="items-center max-w-screen md:mt-16 bg-darkgray">
            <div className="items-center max-w-screen-xl md:px-20 py-8 md:py-10 mx-auto space-y-5 bg-darkgray text-gray-200 text-xs md:text-xs font-extralight">
                <div className="flex relative space-x-20 justify-center md:justify-normal items-center">
                    <img
                        src="/megatoon_white.png"
                        className="w-28 md:w-[170px]"
                        alt="logo"
                    ></img>
                </div>
                <div className="flex relative space-x-2 md:space-x-3 justify-center md:justify-normal">
                    <span>개인정보 처리방침</span>
                    <span>|</span>
                    <span>이용약관</span>
                    <span>|</span>
                    <span>고객센터</span>
                    <span>|</span>
                    <span>
                        <Link to="https://t.me/megatoon100" target="_blank">
                            광고제휴문의 (텔레그램)
                        </Link>
                    </span>
                </div>
                <div className="hidden md:flex flex-col justify-normal text-left px-4 md:px-0">
                    <p>
                        본 사이트에서 제공하는 웹툰 서비스는 인터넷에서 수집한
                        자료입니다. 본 서버에 어떠한 자료도 저장하지 않습니다.
                    </p>
                    <p>
                        모든 콘텐츠의 저작권은 저작권자에 있으며, 이를 무단으로
                        이용하는 경우 저작권법 등에 따라 법적 책임을 질 수
                        있습니다.
                    </p>
                </div>
                <div className="flex md:hidden justify-center text-xs text-[12px] text-left px-5">
                    <p>
                        본 사이트에서 제공하는 웹툰 서비스는 인터넷에서 수집한
                        자료입니다. 본 서버에 어떠한 자료도 저장하지 않습니다.
                        모든 콘텐츠의 저작권은 저작권자에 있으며, 이를 무단으로
                        이용하는 경우 저작권법 등에 따라 법적 책임을 질 수
                        있습니다.
                    </p>
                </div>
                <div className="text-center md:text-left">
                    Copyright © 2024 MEGATOON All Rights Reserved.
                </div>
            </div>
        </footer>
    );
};

export default Footer;
