import React, { useState } from "react";
import { pDays, Tags } from "../MetaData";

const WeeklyMenu = ({ onMenuClick }) => {
    const [selectedMenu, setSelectedMenu] = useState({type:"day", value: null});

    const handleDayClick = (day) => {
        setSelectedMenu({ type: "day", value: day });
        onMenuClick({ type: "day", value: day });
    };

    const handleTagClick = (tag) => {
        setSelectedMenu({ type: "tag", value: tag });
        onMenuClick({ type: "tag", value: tag });
    };

    return (
        <div className="">
            <div className="border-b pt-2 md:pt-3 bg-gray-100">
                <div className="flex justify-center md:justify-start mx-auto text-sm md:text-base space-x-4 md:space-x-5 max-w-screen-xl px-3 md:px-20 mb-0.5">
                    {pDays.map((day, index) => (
                        <button
                            key={index}
                            className={`p-0 px-1 md:px-2 mb-1 md:mb-2 rounded-3xl  ${
                                selectedMenu.type === "day" &&
                                selectedMenu.value === day.value
                                    ? "text-white bg-theme_rose"
                                    : ""
                            }`}
                            onClick={() => handleDayClick(day.value)}
                        >
                            {day.name}
                        </button>
                    ))}
                </div>
            </div>

            <div className="max-w-screen-xl mt-5 px-3 md:px-20 mx-auto font-normal md:font-semibold text-xs md:text-sm text-gray-500">
                {Tags.map((tag, index) => (
                    <button
                        key={index}
                        className={`bg-gray-100 p-2 md:p-2.5 px-1.5 md:px-2.5 mr-1.5 mb-1 md:mb-1.5 rounded ${
                            selectedMenu.type === "tag" &&
                            selectedMenu.value === tag.value
                                ? "bg-theme_rose text-white"
                                : ""
                        }`}
                        onClick={() => handleTagClick(tag.value)}
                    >
                        #{tag.name}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default WeeklyMenu;
