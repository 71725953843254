import React, { useState } from "react";
import { Helmet } from "react-helmet";

import WeeklyList from "./view/WeeklyList";
import Banner from "./view/Banner";
import WeeklyMenu from "./view/WeeklyMenu";

const BLGL = () => {
    const [selectedMenu, setSelectedMenu] = useState({
        type: "day",
        value: null,
    });

    const handleMenuClick = (selectedMenu) => {
        setSelectedMenu(selectedMenu);
    };


    return (
        <div className="">
            <Helmet>
                <title>메가툰 - BL/GL 웹툰</title>
                <meta
                    name="description"
                    content="나만의 취향 저격 BL GL 웹툰. 쌓아두고 다시 보기"
                />
            </Helmet>

            <WeeklyMenu onMenuClick={handleMenuClick} />

            <WeeklyList category="blgl" title="BL/GL" menu={selectedMenu} />

            <Banner />
        </div>
    );
};

export default BLGL;
