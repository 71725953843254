import React, { useState } from "react";
import { Helmet } from "react-helmet";

import WeeklyList from "./view/WeeklyList";
import Banner from "./view/Banner";
import WeeklyMenu from "./view/WeeklyMenu";

const General = () => {
   const [selectedMenu, setSelectedMenu] = useState({
       type: "day",
       value: "null",
   });

    const handleMenuClick = (selectedMenu) => {
        setSelectedMenu(selectedMenu);
        console.log(selectedMenu);
    };

    return (
        <div className="">
            <Helmet>
                <title>메가툰 - 일반 웹툰</title>
                <meta
                    name="description"
                    content="인기 웹툰 기다리지 말고 무료로 미리 보기"
                />
            </Helmet>
            <WeeklyMenu onMenuClick={handleMenuClick} />

            <WeeklyList
                category="general"
                title="일반 웹툰"
                menu={selectedMenu}
            />

            <Banner />
        </div>
    );
};

export default General;
