import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { API_SERVER, IMG_SERVER } from "./config";

const Detail = () => {
    const { id } = useParams();
    const [toon, setToon] = useState(null);
    const [episodes, setEpisodes] = useState(null);
    const [sortOrder, setSortOrder] = useState("desc");
    const [currentPage, setCurrentPage] = useState(1);
    const episodesPerPage = 20;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDetail = async () => {
            try {
                const { data } = await axios.get(
                    `${API_SERVER}/detail/${id}`
                );
                setToon(data.toon);
                setEpisodes(data.episodes.reverse());
            } catch (error) {
                console.error("Error fetching detail:", error);
            }
        };
        window.scrollTo(0, 0);
        fetchDetail();
    }, [id]);

    if (!toon) {
        return (
            <div className="flex max-w-screen-xl -mt-32 h-screen mx-auto justify-center items-center">
                <img src="/loading.gif" width="96px" alt="loading..." />
            </div>
        );
    }

    const sortAsc = () => {
        const sortedEpisodes = [...episodes].sort(
            (a, b) => a.sequence - b.sequence
        );
        setEpisodes(sortedEpisodes);
        setSortOrder("asc");
    };

    const sortDesc = () => {
        const sortedEpisodes = [...episodes].sort(
            (a, b) => b.sequence - a.sequence
        );
        setEpisodes(sortedEpisodes);
        setSortOrder("desc");
    };

    const goFirstEpisode = () => {
        const url = `/view/${toon.id}/${toon.firstEpisode.id}`;
        navigate(url, { replace: true });
        window.scrollTo(0, 0);
    };

    const indexOfLastEpisode = currentPage * episodesPerPage;
    const indexOfFirstEpisode = indexOfLastEpisode - episodesPerPage;
    const currentEpisodes = episodes
        ? episodes.slice(indexOfFirstEpisode, indexOfLastEpisode)
        : [];

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="max-w-screen-xl mt-7 px-3 md:px-20 mx-auto">
            <Helmet>
                <title>메가툰 - {toon.title}</title>
                <meta
                    name="description"
                    content={toon.synopsis
                        .replace(/[\n\r\t]/g, " ")
                        .replace(/\s+/g, " ")
                        .trim()}
                />
            </Helmet>

            <div className="">
                <div className="flex space-x-3 md:space-x-5 md:h-[290px] ">
                    <div className="w-[120px] md:w-[270px] h-[160px] md:h-[290px] bg-gray">
                        <img
                            src={`${IMG_SERVER}/${toon.image}`}
                            className="w-[120px] md:w-[270px] h-[160px] md:h-[290px] rounded-md object-cover"
                            alt="thumbnail"
                        />
                    </div>

                    <div className="flex flex-col justify-start flex-grow w-7/12">
                        <p className="text-lg md:text-2xl font-bold md:truncate">
                            {toon.title}
                        </p>

                        <div className="py-2 pb-4 md:space-y-1">
                            <p className="flex text-xs md:text-sm text-gray-500">
                                <span className="truncate">
                                    {toon.authors.map((author, index) => (
                                        <React.Fragment key={index}>
                                            {author.name}
                                            {index !==
                                                toon.authors.length - 1 && ", "}
                                        </React.Fragment>
                                    ))}
                                </span>
                            </p>
                            <p className="inline-flex items-center text-gray-500 text-xs mt-1 pl-1 pr-1.5 space-x-1 border">
                                <img
                                    className="w-3 md:w-3.5 h-3 md:h-3.5"
                                    src={`${toon.platforms[0].icon}`}
                                    alt="company"
                                />
                                <span className="flex items-center">
                                    {toon.platforms[0].displayName}
                                </span>
                            </p>
                            <p className="text-xs md:text-sm py-0 my-1 line-clamp-4 overflow-hidden">
                                {toon.synopsis}
                            </p>
                            <p className="flex flex-wrap text-gray-500 text-xs md:text-sm mt-0 gap-1">
                                {toon.tags.slice(0, 12).map((tag, index) => (
                                    <span
                                        className="bg-gray-100 px-2 py-1 rounded-sm"
                                        key={index}
                                    >
                                        #{tag.name}
                                    </span>
                                ))}
                            </p>
                        </div>

                        <div className="flex-grow"></div>

                        <div className="hidden md:flex space-x-3 w-96">
                            <button
                                onClick={goFirstEpisode}
                                className="bg-theme_rose text-white flex-grow h-12 rounded font-bold"
                            >
                                첫 화 보기
                            </button>
                        </div>
                    </div>

                    <div className="hidden md:block h-[282px] w-5/12 space-y-1">
                        <img
                            className="h-1/3"
                            src="/banner/head_ad.png"
                            alt="banner"
                        />
                        <img
                            className="h-1/3"
                            src="/banner/head_ad.png"
                            alt="banner"
                        />
                        <img
                            className="h-1/3"
                            src="/banner/head_ad.png"
                            alt="banner"
                        />
                    </div>
                </div>
                <div className="md:hidden flex space-x-2 w-full">
                    <button
                        onClick={goFirstEpisode}
                        className="bg-theme_rose text-white text-sm md:text-base flex-grow h-10 md:h-12 rounded font-bold"
                    >
                        첫 화 보기
                    </button>
                </div>

                <div className="flex border-b pt-8 md:pt-10 pb-2 md:pb-5 mb-1 md:mb-8 text-sm md:text-base font-bold">
                    <p className="w-1/2">총 {episodes.length}개의 에피소드</p>
                    <p className="w-1/2 text-xs md:text-sm text-right text-gray-400">
                        <button
                            onClick={sortDesc}
                            className={`${
                                sortOrder === "desc" ? "text-theme_rose" : ""
                            }`}
                        >
                            최신화부터
                        </button>
                        <span className="px-0.5">·</span>
                        <button
                            onClick={sortAsc}
                            className={`${
                                sortOrder === "asc" ? "text-theme_rose" : ""
                            }`}
                        >
                            1화부터
                        </button>
                    </p>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-5 text-xs md:text-sm -mx-2">
                    {currentEpisodes.map((episode, index) => (
                        <Link
                            to={`/view/${toon.id}/${episode.id}`}
                            className="p-1 md:p-1.5 mb-4"
                            key={index}
                        >
                            <img
                                src={`${IMG_SERVER}/${episode.image}`}
                                className="w-full h-20 md:w-[215px] md:h-[107px] object-cover rounded"
                                alt={episode.title}
                            />
                            <div className="mt-1">
                                <p className="font-bold">{episode.title}</p>
                                <p className="text-gray-500">
                                    {episode.createdAt}
                                </p>
                            </div>
                        </Link>
                    ))}
                </div>

                <div className="flex justify-center mt-8">
                    {episodes && (
                        <Pagination
                            episodesPerPage={episodesPerPage}
                            totalEpisodes={episodes.length}
                            currentPage={currentPage}
                            paginate={paginate}
                        />
                    )}
                </div>
            </div>

            <div className="block md:hidden w-full space-y-1 mt-10 py-5">
                <img
                    className="w-full"
                    src="/banner/head_ad.png"
                    alt="banner"
                />
                <img
                    className="w-full"
                    src="/banner/head_ad.png"
                    alt="banner"
                />
                <img
                    className="w-full"
                    src="/banner/head_ad.png"
                    alt="banner"
                />
            </div>
        </div>
    );
};

const Pagination = ({
    episodesPerPage,
    totalEpisodes,
    currentPage,
    paginate,
}) => {
    const pageNumbers = [];

    const totalPages = Math.ceil(totalEpisodes / episodesPerPage);
    const pageRange = 10;
    const startPage = Math.max(
        1,
        Math.min(
            currentPage - Math.floor(pageRange / 2),
            totalPages - pageRange + 1
        )
    );
    const endPage = Math.min(totalPages, startPage + pageRange - 1);

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    const goToPrevPage = () => {
        if (currentPage > 1) {
            paginate(currentPage - 1);
        }
    };

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            paginate(currentPage + 1);
        }
    };

    const goToFirstPage = () => {
        paginate(1);
    };

    const goToLastPage = () => {
        paginate(totalPages);
    };

    return (
        <div className="flex items-center">
            <button
                onClick={goToFirstPage}
                disabled={currentPage === 1}
                className="mx-0.5 px-0.5 py-1 md:p-1 w-5 md:w-8 text-xs md:text-base bg-gray-200 rounded"
            >
                &lt;&lt;
            </button>
            <button
                onClick={goToPrevPage}
                disabled={currentPage === 1}
                className="mx-0.5 px-0.5 py-1 md:p-1 w-5 md:w-8 text-xs md:text-base bg-gray-200 rounded"
            >
                &lt;
            </button>
            {pageNumbers.map((number) => (
                <button
                    key={number}
                    onClick={() => paginate(number)}
                    className={`mx-0.5 md:mx-1 px-0.5 py-1 md:p-1 w-5 md:w-8 text-xs md:text-base ${
                        currentPage === number
                            ? "bg-theme_rose text-white"
                            : "bg-gray-200"
                    } rounded`}
                >
                    {number}
                </button>
            ))}
            <button
                onClick={goToNextPage}
                disabled={currentPage === totalPages}
                className="mx-0.5 px-0.5 py-1 md:p-1 w-5 md:w-8 text-xs md:text-base bg-gray-200 rounded"
            >
                &gt;
            </button>
            <button
                onClick={goToLastPage}
                disabled={currentPage === totalPages}
                className="mx-0.5 px-0.5 py-1 md:p-1 w-5 md:w-8 text-xs md:text-base bg-gray-200 rounded"
            >
                &gt;&gt;
            </button>
        </div>
    );
};

export default Detail;
