const banner_400 = [
    {
        img: "barobet_400x100.gif",
        href: "https://baro.bet/sports/prematch?referral=1818",
    },
    {
        img: "indiebet_400x100.gif",
        href: "https://ind-domain03.com//?ref=1919",
    },
    { img: "neobet_400x100.gif", href: "http://nbtpass.com/" },
    { img: "ygy_400x100.png", href: " https://www.ygy01.com/" },
    { img: "sureman_400x100.gif", href: "https://suerman.net/" },
    {
        img: "premier_400x100.png",
        href: "https://www.xn--oy2b25boyhuze91e5vw.com/",
    },
];
const banner_1200 = {
    img: "barobet_1200x120.gif", 
    img_mobile: "barobet_800x100.gif",
    href: "https://baro.bet/sports/prematch?referral=1818"
}

const TopBanner = () => {
    return (
        <div className="flex flex-col justify-center items-center max-w-screen-xl md:px-20 md:mt-10 mb-4 md:mb-10 mx-auto overflow-hidden">
            <div className="grid grid-cols-2 md:grid-cols-3 mt-4 md:mt-0">
                {banner_400.map((banner, index) => (
                    <a href={`${banner.href}`} target="_blank" rel="noreferrer">
                        <img
                            key={index}
                            src={`/banner/${banner.img}`}
                            alt="logo"
                        />
                    </a>
                ))}
            </div>
            <a href={`${banner_1200.href}`} target="_blank" rel="noreferrer">
                <img className="hidden md:flex" src={`/banner/${banner_1200.img}`} alt="logo" />
                <img className="flex md:hidden" src={`/banner/${banner_1200.img_mobile}`} alt="logo" />
            </a>
        </div>
    );
};

export default TopBanner;
