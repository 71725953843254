export const company = [
    { id: 0, name: "기타웹툰" },
    { id: 1, name: "네이버웹툰" },
    { id: 2, name: "다음웹툰" },
    { id: 3, name: "카카오웹툰" },
    { id: 4, name: "레진코믹스" },
    { id: 5, name: "투믹스" },
    { id: 6, name: "탑툰" },
    { id: 7, name: "코미카" },
    { id: 8, name: "배틀코믹스" },
    { id: 9, name: "코믹GT" },
    { id: 10, name: "케이툰" },
    { id: 11, name: "애니툰" },
    { id: 12, name: "폭스툰" },
    { id: 13, name: "피넛툰" },
    { id: 14, name: "봄툰" },
    { id: 15, name: "코미코" },
    { id: 16, name: "무툰" },
    { id: 99, name: "기타" },
];

export const Tags = [
    { id: 1, name: "드라마", value: "드라마" },
    { id: 2, name: "로맨스", value: "로맨스" },
    { id: 3, name: "판타지", value: "판타지" },
    { id: 4, name: "학원", value: "학원" },
    { id: 5, name: "일상", value: "일상" },
    { id: 6, name: "감성", value: "감성" },
    { id: 7, name: "BL", value: "BL" },
    { id: 8, name: "액션", value: "액션" },
    { id: 9, name: "개그", value: "개그" },
    { id: 10, name: "공포/스릴러", value: "공포%2F스릴러" },
    { id: 11, name: "스포츠", value: "스포츠" },
    { id: 12, name: "무협/사극", value: "무협%2F사극" },
    { id: 13, name: "GL", value: "GL" },
];

export const pDays = [
    { id: 0, name: "전체", value: null },
    { id: 1, name: "월", value: "mon" },
    { id: 2, name: "화", value: "tue" },
    { id: 3, name: "수", value: "wed" },
    { id: 4, name: "목", value: "thu" },
    { id: 5, name: "금", value: "fri" },
    { id: 6, name: "토", value: "sat" },
    { id: 7, name: "일", value: "sun" },
    { id: 10, name: "열흘", value: 10 }
];
