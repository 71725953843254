import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { API_SERVER, IMG_SERVER } from "../config";

const WeeklyList = ({ category, title, menu }) => {
    const [data, setData] = useState(null);
    const [orderBy, setOrderBy] = useState("view");
    const [cursor, setCursor] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = useCallback(async (cursorParam = null) => {
        try {
            cursorParam === null && setData(null);

            setIsLoading(true);
            const cursorToSend = cursorParam === null ? "null" : cursorParam;

            let targetUrl =
                menu.type === "day"
                    ? `${API_SERVER}/weekly/${category}/${orderBy}/${menu.value}/${cursorToSend}`
                    : `${API_SERVER}/tag/${category}/${orderBy}/${menu.value}/${cursorToSend}`;

            const { data: newData } = await axios.get(targetUrl);

            if (cursorParam === null) {
                setData(newData);
            } else {
                setData((prevData) => ({
                    ...prevData,
                    toons: [...prevData.toons, ...newData.toons],
                    total: newData.total,
                }));
            }
            setCursor((prevCursor) => prevCursor + newData.toons.length);
            setHasMore(newData.toons.length === 30);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    }, [category, menu, orderBy]);

    useEffect(() => {
        setCursor(null);
        fetchData();
    }, [category, menu, orderBy, fetchData]);

    const fetchMoreData = () => {
        if (hasMore && !isLoading) {
            fetchData(cursor);
        }
    };

    if (!data) {
        return (
            <div className="max-w-screen-xl mt-3 md:mt-7 px-3 md:px-20 mx-auto">
                <div className="flex font-bold space-x-5 py-1 -mr-1">
                    <span className="text-base md:text-xl w-1/2">{title}</span>

                    <span className="flex w-full text-sm md:text-base justify-end text-gray-500">
                        <button
                            className={`px-1 ${
                                orderBy === "view" ? "text-theme_rose" : ""
                            }`}
                            onClick={() => setOrderBy("view")}
                        >
                            인기순
                        </button>
                        ·
                        <button
                            className={`px-1 ${
                                orderBy === "update" ? "text-theme_rose" : ""
                            }`}
                            onClick={() => setOrderBy("update")}
                        >
                            최신순
                        </button>
                    </span>
                </div>
                <div className="flex max-w-screen-xl -mt-32 h-screen mx-auto justify-center items-center">
                    <img src="/loading.gif" width="96px" alt="loading..." />
                </div>
            </div>
        );
    }

    function formatNumber(number) {
        return Number(number).toLocaleString();
    }

    return (
        <div className="max-w-screen-xl mt-3 md:mt-7 px-3 md:px-20 mx-auto">
            <div className="flex font-bold space-x-5 py-1 -mr-1">
                <span className="text-base md:text-xl w-2/3">
                    {title}
                    &nbsp;
                    <span className="text-sm md:text-base font-normal">
                        ({data && data.total && formatNumber(data.total)}개)
                    </span>
                </span>

                <span className="flex w-full text-sm md:text-base justify-end text-gray-500">
                    <button
                        className={`px-1 ${
                            orderBy === "view" ? "text-theme_rose" : ""
                        }`}
                        onClick={() => setOrderBy("view")}
                    >
                        인기순
                    </button>
                    ·
                    <button
                        className={`px-1 ${
                            orderBy === "update" ? "text-theme_rose" : ""
                        }`}
                        onClick={() => setOrderBy("update")}
                    >
                        최신순
                    </button>
                </span>
            </div>
            <div className="grid grid-cols-3 md:grid-cols-6 items-center gap-2 md:gap-4 py-2">
                {data.toons.map((toon, index) => (
                    <Link
                        to={`/detail/${toon.id}`}
                        key={index}
                        className="relative"
                    >
                        <img
                            src={`${IMG_SERVER}/${toon.image}`}
                            className="w-full h-[160px] md:h-[225px] rounded-md object-cover"
                            alt="thumbnail"
                        />

                        {toon.isAdult === 1 && (
                            <p className="absolute top-1 md:top-1.5 right-1 md:right-1.5 px-0.5 md:px-1 rounded-sm text-white font-bold bg-red-500 text-xs md:text-sm">
                                19
                            </p>
                        )}

                        <div className="py-2 pb-4 text-sm md:text-base">
                            <p className="font-bold truncate">{toon.title}</p>
                            <p className="flex text-xs md:text text-gray-500">
                                <span className="truncate">
                                    {toon.authors.map((author, index) => (
                                        <React.Fragment key={index}>
                                            {author.name}
                                            {index !==
                                                toon.authors.length - 1 && ", "}
                                        </React.Fragment>
                                    ))}
                                </span>
                            </p>
                            <p className="inline-flex items-center text-gray-500 text-xs mt-1 pl-1 pr-1.5 space-x-1 border">
                                <img
                                    className="w-3 md:w-3.5 h-3 md:h-3.5"
                                    src={`${toon.platforms[0].icon}`}
                                    alt="company"
                                />
                                <span className="flex items-center">
                                    {toon.platforms[0].displayName}
                                </span>
                            </p>
                        </div>
                    </Link>
                ))}
            </div>

            {hasMore && (
                <div className="flex justify-center mt-4 mb-8 md:mb-4">
                    <button
                        className="bg-[#04F2FF] hover:bg-blue-700 text-sm md:text-base text-white font-bold py-2 px-4 rounded"
                        onClick={fetchMoreData}
                        disabled={isLoading}
                    >
                        {isLoading ? "Loading more..." : "More"}
                    </button>
                </div>
            )}
        </div>
    );
};

export default WeeklyList;
