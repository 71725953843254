import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import axios from "axios";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { API_SERVER, IMG_SERVER } from "./config";
import TopBanner from "./view/TopBanner";

const View = () => {
    const { id, episode } = useParams();
    const [view, setView] = useState(null);
    const [previousId, setPreviousId] = useState("");
    const [nextId, setNextId] = useState("");
    const [loadedImages, setLoadedImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setView(null);
        setLoadedImages([]);
        setIsLoading(true);
        const fetchDetail = async () => {
            try {
                const { data } = await axios.get(
                    `${API_SERVER}/view/${id}/${episode}`
                );
                setView(data);

                const prevEpisode = data.episode.prevEpisode;
                setPreviousId(prevEpisode ? prevEpisode.id : null);

                const nextEpisode = data.episode.nextEpisode;
                setNextId(nextEpisode ? nextEpisode.id : null);
            } catch (error) {
                console.error("Error fetching detail:", error);
            }
        };
        
        window.scrollTo(0, 0);
        fetchDetail();
    }, [id, episode]);

    useEffect(() => {
        if (view) {
            const imageUrls = view.episode.files;
            const loadImage = (index) => {
                if (index >= imageUrls.length) return;
                const img = new Image();
                img.src = `${IMG_SERVER}/${imageUrls[index]}`;
                img.onload = () => {
                    setLoadedImages((prevImages) => [
                        ...prevImages,
                        imageUrls[index],
                    ]);
                    if (index === 0) {
                        setIsLoading(false);
                    }
                    loadImage(index + 1);
                };
            };
            loadImage(0);
        }
    }, [view]);

    if (!view) {
        return (
            <div className="flex max-w-screen-xl -mt-32 h-screen mx-auto justify-center items-center">
                <img src="/loading.gif" width="96px" alt="loading..." />
            </div>
        );
    }

    const handleNavigate = (type) => {
        let url;
        switch (type) {
            case "prev":
                url = `/view/${id}/${previousId}`;
                break;
            case "next":
                url = `/view/${id}/${nextId}`;
                break;
            case "list":
                url = `/detail/${id}`;
                break;
            default:
                url = `/detail/${id}`;
                break;
        }
        navigate(url, { replace: true });
    };

    return (
        <div className="w-full border-t border-gray-500">
            <Helmet>
                <title>
                    메가툰 - {view.toon.title} | {view.episode.title}
                </title>
                <meta
                    name="description"
                    content={view.toon.synopsis
                        .replace(/[\n\r\t]/g, " ")
                        .replace(/\s+/g, " ")
                        .trim()}
                />
            </Helmet>
            <div className="flex md:hidden sticky top-0 z-50 items-center h-12 md:h-14 bg-darkgray">
                <div className="flex max-w-screen-xl px-3 mx-auto text-white w-full">
                    <div className="flex w-full justify-center">
                        <Link to={`/detail/${id}`} className="pr-4 flex">
                            {view.toon.title}
                        </Link>
                        <span className="text-base">|</span>
                        <p className="px-4">{view.episode.title}</p>
                    </div>
                </div>
            </div>

            <div className="hidden md:flex items-center h-12 md:h-14 bg-darkgray">
                <div className="flex max-w-screen-xl px-20 mx-auto text-white w-full">
                    <div className="flex w-1/2 -ml-2">
                        <Link to={`/detail/${id}`} className="pr-4 flex">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 19l-7-7 7-7"
                                />
                            </svg>
                            {view.toon.title}
                        </Link>
                        <span className="text-base">|</span>
                        <p className="px-4">{view.episode.title}</p>
                    </div>

                    <div className="flex w-1/2 mx-auto text-white justify-end -mr-5">
                        <button
                            disabled={!previousId}
                            onClick={() => previousId && handleNavigate("prev")}
                            className={`mx-4 flex ${
                                !previousId
                                    ? "opacity-50 cursor-not-allowed"
                                    : ""
                            }`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 18 18"
                                fill="currentColor"
                                className="w-4 h-4 mt-1 mr-1"
                                transform="scale(-1, 1)"
                            >
                                <path d="M3 3.732a1.5 1.5 0 0 1 2.305-1.265l6.706 4.267a1.5 1.5 0 0 1 0 2.531l-6.706 4.268A1.5 1.5 0 0 1 3 12.267V3.732Z" />
                            </svg>
                            이전화
                        </button>
                        <span className="text-base text-gray-500">|</span>
                        <button
                            onClick={() => handleNavigate("list")}
                            className="mx-4 flex"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="w-5 h-5 mt-0.5 mr-1"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M6 4.75A.75.75 0 0 1 6.75 4h10.5a.75.75 0 0 1 0 1.5H6.75A.75.75 0 0 1 6 4.75ZM6 10a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H6.75A.75.75 0 0 1 6 10Zm0 5.25a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H6.75a.75.75 0 0 1-.75-.75ZM1.99 4.75a1 1 0 0 1 1-1H3a1 1 0 0 1 1 1v.01a1 1 0 0 1-1 1h-.01a1 1 0 0 1-1-1v-.01ZM1.99 15.25a1 1 0 0 1 1-1H3a1 1 0 0 1 1 1v.01a1 1 0 0 1-1 1h-.01a1 1 0 0 1-1-1v-.01ZM1.99 10a1 1 0 0 1 1-1H3a1 1 0 0 1 1 1v.01a1 1 0 0 1-1 1h-.01a1 1 0 0 1-1-1V10Z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            목록
                        </button>
                        <span className="text-base text-gray-500">|</span>
                        <button
                            disabled={!nextId}
                            onClick={() => nextId && handleNavigate("next")}
                            className={`mx-4 flex ${
                                !nextId ? "opacity-50 cursor-not-allowed" : ""
                            }`}
                        >
                            다음화
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 18 18"
                                fill="currentColor"
                                className="w-4 h-4 mt-1 ml-1"
                            >
                                <path d="M3 3.732a1.5 1.5 0 0 1 2.305-1.265l6.706 4.267a1.5 1.5 0 0 1 0 2.531l-6.706 4.268A1.5 1.5 0 0 1 3 12.267V3.732Z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <TopBanner />

            <div className="max-w-screen-xl mt-0 md:mt-4 px-0 md:px-20 mx-auto">
                <div className="flex justify-center">
                    <div className="w-full md:w-2/3">
                        {isLoading && (
                            <div className="flex max-w-screen-xl -mt-32 h-screen mx-auto justify-center items-center">
                                <img
                                    src="/loading.gif"
                                    width="96px"
                                    alt="loading..."
                                />
                            </div>
                        )}
                        {loadedImages.map((image, index) => (
                            <div key={index}>
                                <img
                                    src={`${IMG_SERVER}/${image}`}
                                    className="w-full"
                                    alt="img"
                                />
                            </div>
                        ))}

                        <div className="flex w-full h-40 items-center justify-center space-x-3 my-10 font-bold">
                            <img
                                className="w-full"
                                src="/banner/head_ad.png"
                                alt="banner"
                            />
                        </div>

                        <div className="hidden md:flex w-full items-center justify-center space-x-3 mt-20 text-gray-800 font-bold">
                            <button
                                disabled={!previousId}
                                onClick={() =>
                                    previousId && handleNavigate("prev")
                                }
                                className={`flex-grow h-14 bg-gray-100 border-gray-200 border rounded ${
                                    !previousId
                                        ? "opacity-50 cursor-not-allowed"
                                        : ""
                                }`}
                            >
                                이전화
                            </button>
                            <button
                                onClick={() => handleNavigate("list")}
                                className="flex-grow h-14 border-gray-200 border rounded"
                            >
                                목록
                            </button>
                            <button
                                disabled={!nextId}
                                onClick={() => nextId && handleNavigate("next")}
                                className={`flex-grow h-14 bg-gray-100 border-gray-200 border rounded ${
                                    !nextId
                                        ? "opacity-50 cursor-not-allowed"
                                        : ""
                                }`}
                            >
                                다음화
                            </button>
                        </div>

                        <div className="flex md:hidden fixed bottom-0 text-sm md:text-base items-center w-full h-12 md:h-14 bg-darkgray z-50">
                            <div className="flex w-full mx-auto text-white justify-center">
                                <button
                                    disabled={!previousId}
                                    onClick={() =>
                                        previousId && handleNavigate("prev")
                                    }
                                    className={`mx-4 w-1/4 mt-1 flex justify-center ${
                                        !previousId
                                            ? "opacity-50 cursor-not-allowed"
                                            : ""
                                    }`}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 6 10"
                                        fill="none"
                                        className="w-3 h-3 mt-1 mr-1"
                                    >
                                        <path
                                            d="M4.874 0.178483L0.238117 4.44436C0.163433 4.51312 0.103483 4.59841 0.0623818 4.69439C0.0212805 4.79037 0 4.89476 0 5.0004C0 5.10604 0.0212805 5.21043 0.0623818 5.30641C0.103483 5.40239 0.163433 5.48769 0.238117 5.55645L4.874 9.82232C5.3165 10.2294 6 9.8919 6 9.26627V0.733309C6 0.10768 5.3165 -0.229854 4.874 0.178483Z"
                                            fill="white"
                                        />
                                    </svg>
                                    이전화
                                </button>
                                <span className="text-base text-gray-500">
                                    |
                                </span>
                                <button
                                    onClick={() => handleNavigate("list")}
                                    className="mx-4 flex w-auto mt-1"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 18 18"
                                        fill="currentColor"
                                        className="w-5 md:w-5 h-5 md:h-5 mt-0 md:mt-0 mr-1"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M6 4.75A.75.75 0 0 1 6.75 4h10.5a.75.75 0 0 1 0 1.5H6.75A.75.75 0 0 1 6 4.75ZM6 10a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H6.75A.75.75 0 0 1 6 10Zm0 5.25a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H6.75a.75.75 0 0 1-.75-.75ZM1.99 4.75a1 1 0 0 1 1-1H3a1 1 0 0 1 1 1v.01a1 1 0 0 1-1 1h-.01a1 1 0 0 1-1-1v-.01ZM1.99 15.25a1 1 0 0 1 1-1H3a1 1 0 0 1 1 1v.01a1 1 0 0 1-1 1h-.01a1 1 0 0 1-1-1v-.01ZM1.99 10a1 1 0 0 1 1-1H3a1 1 0 0 1 1 1v.01a1 1 0 0 1-1 1h-.01a1 1 0 0 1-1-1V10Z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    목록
                                </button>
                                <span className="text-base text-gray-500">
                                    |
                                </span>
                                <button
                                    disabled={!nextId}
                                    onClick={() =>
                                        nextId && handleNavigate("next")
                                    }
                                    className={`mx-4 w-1/4 flex mt-1 md:mt-0 justify-center ${
                                        !nextId
                                            ? "opacity-50 cursor-not-allowed"
                                            : ""
                                    }`}
                                >
                                    다음화
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 6 10"
                                        fill="none"
                                        className="w-3 h-3 mt-1 ml-1"
                                    >
                                        <path
                                            d="M1.126 9.82152L5.76188 5.55564C5.83657 5.48688 5.89652 5.40159 5.93762 5.30561C5.97872 5.20963 6 5.10524 6 4.9996C6 4.89396 5.97872 4.78957 5.93762 4.69359C5.89652 4.59761 5.83657 4.51231 5.76188 4.44355L1.126 0.177679C0.683503 -0.229437 8.64778e-07 0.108097 8.10083e-07 0.733725L6.41079e-08 9.26669C9.41371e-09 9.89232 0.683502 10.2299 1.126 9.82152Z"
                                            fill="white"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default View;
